<template>
  <div class='wameed-dashboard-page-content'>
    <page-header
      :title="$t('featured.title')"

      :btn-title="$t('featured.new_btn')"

      :btn='true'
      @btnAction='showModal = true;modalData=null'
    />

    <div>
      <filter-header
        :content='filterContent'
        @applyFilter='applyFilter'
        @resetFilter='resetFilter'
        @orderApplicants='orderData'

      />
    </div>
    <section
      class='
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      '>
      <div class='d-flex flex-wrap flex-1'>
        <b-col lg='12' md='12' class='px-0'>

          <div class='w-table d-flex flex-column'>
            <w-tables
              :noDataTitle="$t('table.no_data_title')"
              :noDataSubtitle="$t('table.no_data_subtitle')"
              v-if='getData'
              :per-page='filterData.per_page'
              :items='getData'
              :fields='tableFields'
              :custimized-items="[
                { name: 'name' },
                { name: 'status' },
                { name: 'action' },

                { name: 'start_at' },
                { name: 'end_at' },
              ]"
            >

              <template slot='start_at' slot-scope='{ data }'>
                <div class='d-flex'>
                  <span class='text-font-main mx-3'>
                    <span v-if='data.item.start_at'>

                    {{ formatDate(data.item.start_at, 'YYYY/MM/DD') }}
                    </span>
                    <span v-else>
                      -
                    </span>
                  </span>
                </div>
              </template>

              <template slot='end_at' slot-scope='{ data }'>
                <div class='d-flex'>
                  <span class='text-font-main mx-3'>
                    <span v-if='data.item.start_at'>
                    {{ formatDate(data.item.end_at, 'YYYY/MM/DD') }}
                    </span>
                  <span v-else>
                      -
                    </span>
                  </span>
                </div>
              </template>
              <template slot='name' slot-scope='{ data }'>
                <name-tile
                  :name='data.item.name'
                  :image='data.item.image'
                />
              </template>


              <template slot='status' slot-scope='{ data }'>
                <wameed-switch
                  :disabled='data.item.disable ===1'
                  :checked='data.item.status ===1'
                  @onChange='(e)=>{e.preventDefault(); onChange(data.item)}' />
              </template>

              <template slot='action' slot-scope='{ data }'
                        v-if='data.item.type==null'>
                <b-dropdown
                  variant='background'
                  class='table-option'
                  toggle-class='text-decoration-none rounded-10 px-2 py-2 '
                  menu-class='rounded-8'
                  no-caret
                  dropleft
                >
                  <template v-slot:button-content>
                    <vertical-dots-icon />
                  </template>

                  <b-dropdown-item
                    class='permission-edit-item'
                    v-permission='[$route.meta.pagePermissions.edit]'
                    @click='()=> openUpdateModal(data.item)'
                  >
                    <span class='text-regular-14 text-font-secondary'>

                      <edit-icon class='mx-2' />
                      {{ $t('btn.edit') }}
                    </span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    class='permission-delete-item'
                    v-permission='[$route.meta.pagePermissions.delete]'
                    @click='deleteModal = true; modalData = data.item'>
                    <span class='text-regular-14  text-danger'>
                      <trash-icon class='mx-2 ' />
                      {{ $t('btn.delete') }}
                    </span>
                  </b-dropdown-item>

                </b-dropdown>
              </template>
            </w-tables>
          </div>

        </b-col>

        <b-col lg='12' md='12' class='px-0'>
          <wameed-pagination
            v-if='getTotal'
            v-model='filterData.page'
            :page='filterData.page'
            :total-items='getTotal.totalItems'
            :per_page='filterData.per_page'
            :menu-title="$t('common.show_results')"
            @changePage='changePage'
            @changeCurrentPage='changeCurrentPage'
          />
        </b-col>

      </div>

    </section>

    <featured-modal
      :visible='showModal'
      @close='showModal = false'
      :modal-data='modalData'
      @onComplete='()=>loadData()'
    ></featured-modal>


    <warning-modal
      variant='danger'
      iconName='trash-icon'
      :visible='deleteModal'
      @close='deleteModal = false'
      @submitAction='deleteAction'
      :title="$t('featured.modal.delete')"
      :subTitle="$t('featured.modal.delete_desc')"
      :btnTitle="$t('btn.delete')"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import FilterHeader from '@/components/wameed/WameedFilterHeader.vue';
import WarningModal from '@/components/WarningModal';

import { WameedPagination, WameedSwitch, WameedTables as WTables } from 'wameed-ui/dist/wameed-ui.esm';
import NameTile from '@/components/nameTile.table';
import FeaturedModal from '@/views/pages/featured/components/featuredModal.vue';

export default {
  components: {
    FeaturedModal,
    NameTile,
    WarningModal,
    WameedPagination,
    PageHeader,
    FilterHeader,
    WameedSwitch,
    WTables
  },
  data() {
    return {
      type:'',
      showModal: false,
      deleteModal: false,
      modalData: null,
      filterData: {
        status: '',
        page: 1,
        per_page: 20,
        order_by: 'desc',
        search: ''
      },
      filterContent: [
        {
          title: this.$i18n.t('table.availability'),
          key: 'status',
          id: 'id',
          name: 'title',
          selectedData: [],
          translate: true,
          type: 'text',
          data: [
            {
              id: 'active',
              title: this.$i18n.t('status.available')
            },
            {
              id: 'not_active',
              title: this.$i18n.t('status.not_available')
            }
          ]
        }
      ],
      tableFields: [
        {
          key: 'index',
          label: '#',
          sortable: false

        },
        {
          key: 'name',
          label: this.$i18n.t('featured.form.name'),
          sortable: false,
          tdClass: 'w-2/5'
        },
        {
          key: 'start_at',
          label: this.$i18n.t('featured.table.start_at'),
          sortable: false,
          tdClass: 'w-1/8'
        },

        {
          key: 'end_at',
          label: this.$i18n.t('featured.table.end_at'),
          sortable: false,
          tdClass: 'w-1/8'
        },

        {
          key: 'status',
          label: this.$i18n.t('table.availability'),
          sortable: false,
          tdClass: 'w-1/5'
        },
        {
          key: 'action',
          label: this.$i18n.t('table.options'),
          tdClass: 'w-1/5'
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      getData: 'admin/featured/getData',
      getTotal: 'admin/featured/getTotal'
    })
  },
  async created() {
    await this.$store.dispatch('admin/featured/getCategories');
    this.loadVendors();
    this.loadData();
  },
  watch: {
    $route(to, from) {
      this.loadData();
      this.loadVendors();
    }
  },
  methods: {
    ...mapActions({
      _getData: 'admin/featured/get',
      _deleteData: 'admin/featured/delete'
    }),

    async loadVendors() {

      let type = this.$route.meta.type;
      await this.$store.dispatch('admin/featured/getVendors', type);
    },
    async openUpdateModal(item) {

      this.showModal = true;
      this.modalData = {
        id: item.id,
        service_id: item.service_id,
        vendor_id: item.vendor_id,
        start_at: item.start_at,
        end_at: item.end_at
      };
    },

    onChange(item) {
      let status = 1;
      if (item.status === 1) {
        status = 0;
      }

      this.$store.dispatch('admin/featured/toggle', { id: item.id, status: status, service_type: this.type  });
    },

    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
        page: 1
      };
      this.loadData();
    },

    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event
      };
      this.loadData();
    },

    loadData() {
      this.type = this.$route.meta.type;

      this._getData({ ...this.filterData, service_type: this.type });
    },

    deleteAction() {
      this.deleteModal = false;

      this._deleteData({ id: this.modalData.id, service_type: this.type  });
    },


    applyFilter() {
      this.filterContent.forEach(item => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },


    resetFilter() {
      this.filterContent.forEach((item, i) => {
        this.filterContent[i].selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },


    orderData(item) {
      this.filterData = {
        ...this.filterData,
        ...item
      };
      this.loadData();
    }
  }
};
</script>
